import { useContext } from "react";
import { MainContext } from "../App";

function FlowPopup() {
	const { table, setProductSheet, setPopupVisible, popupData } = useContext(MainContext);

	const flows = (function () {
		let arr = [];
		for (let i = parseInt(table.flow) + 1; i <= 5; i++) arr.push(i);
		return arr;
	})();

	const handleChoice = (flow) => {
		popupData.callback(popupData.product, popupData.variants, popupData.ingredients, flow);
		setPopupVisible(false);
		setProductSheet(false);
	};

	return (
		<div className="popup popup-small">
			<div className="container">
				<header>
					<div className="buttons-group">
						<button
							className="btn btn-tertiary btn-square"
							onClick={() => setPopupVisible(false)}
						>
							&times;
						</button>
						<div>Scegli la portata</div>
					</div>
				</header>
				{flows.map((el, i) => (
					<button onClick={() => handleChoice(el)} key={i} className="list-button">
						Portata {el}
					</button>
				))}
			</div>
		</div>
	);
}

export default FlowPopup;
