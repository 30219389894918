import { useContext, useState } from "react";
import { MainContext } from "../../App";
import functions from "../../utilities/functions";
import NoteIcon from "../../icons/NoteIcon";
import EditIcon from "../../icons/EditIcon";
import ChevronUp from "../../icons/ChevronUp";
import ChevronDown from "../../icons/ChevronDown";

function Product({ product, i }) {
	const { settings, setVariantsPopup, editQty, editFlow, setPopupVisible, setPopupData } =
		useContext(MainContext);
	const [panelVisible, setPanelVisible] = useState(false);

	const handleClick = () => {
		if (settings.waiter_app.enable_flow == 1) {
			setPanelVisible(panelVisible ? false : true);
		} else {
			setPopupVisible("productNotes");
			setPopupData(product);
		}
	};

	return (
		<div key={i}>
			<div className="list-button vertical">
				{settings.waiter_app.enable_flow == 1 && panelVisible && (
					<div className="buttons-group box">
						<button
							className="btn btn-secondary btn-square sm"
							onClick={() => {
								setPopupVisible("productNotes");
								setPopupData(product);
							}}
						>
							<NoteIcon />
						</button>
						<div className="separator-vertical"></div>
						{product.flow > 1 ? (
							<button
								className="btn btn-secondary btn-square sm"
								onClick={() => editFlow(product.index, "minus")}
							>
								<ChevronUp />
							</button>
						) : (
							<button className="btn btn-square sm">
								<ChevronUp />
							</button>
						)}
						{product.flow < 5 ? (
							<button
								className="btn btn-secondary btn-square sm"
								onClick={() => editFlow(product.index, "plus")}
							>
								<ChevronDown />
							</button>
						) : (
							<button className="btn btn-square sm">
								<ChevronDown />
							</button>
						)}
						{product.selectedVariants && (
							<>
								<div className="separator-vertical"></div>
								<button
									className="btn btn-secondary btn-square sm"
									onClick={() =>
										setVariantsPopup({
											product: product,
											index: i,
										})
									}
								>
									<EditIcon />
								</button>
							</>
						)}
					</div>
				)}
				<div className="buttons-group full text-box">
					<button onClick={() => handleClick()} className="product-name">
						<div>
							<b>{product.name}</b>
							<div className="small">
								{functions.formatter.format(product.price)}
								{product.selectedVariants &&
									product.selectedVariants?.map(
										(variants_category, n) =>
											variants_category.tot > 0 && (
												<div key={n}>
													<b>{variants_category.name}</b>
													{variants_category.variants?.map(
														(variant, l) =>
															variant.qty > 0 && (
																<div key={l}>
																	{variant.qty}x {variant.name}
																</div>
															)
													)}
													{variants_category.products?.map(
														(subproduct, l) =>
															subproduct.qty > 0 && (
																<div key={l}>
																	{subproduct.qty}x{" "}
																	{subproduct.name}
																</div>
															)
													)}
												</div>
											)
									)}
								{product.selectedIngredients.length > 0 && (
									<div>
										<b>Ingredienti rimossi</b>
										{product.selectedIngredients.map(
											(ingredient, i) =>
												!ingredient.selected && (
													<div key={i}>No {ingredient.name}</div>
												)
										)}
									</div>
								)}
							</div>
						</div>
						<div className="small">{product.notes}</div>
					</button>
					<div className="plus_minus_container">
						{functions.formatter.format(
							parseInt(product.qty) * parseFloat(product.price)
						)}
						<button
							className="btn btn-primary btn-square"
							onClick={() => editQty(product.index, "minus")}
						>
							&#8722;
						</button>
						<div>{product.qty}</div>
						<button
							className="btn btn-primary btn-square"
							onClick={() => editQty(product.index, "plus")}
						>
							&#43;
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Product;
