import React, { useContext } from "react";
import { MainContext } from "../App";
import UserIcon from "../icons/UserIcon";
import ShopIcon from "../icons/ShopIcon";

function Menu() {
	const {
		settings,
		courses,
		setShop,
		user,
		setUser,
		setMenuVisible,
		setAlertData,
		category,
		setCategory,
		shop,
		table,
	} = useContext(MainContext);

	return (
		<div className="menu">
			<div className="content">
				<div className="menu-header">
					<div>Ciao, {user.name}</div>
					<button
						className="btn btn-tertiary btn-square"
						onClick={() => setMenuVisible(false)}
					>
						&times;
					</button>
				</div>

				<div className="scroll-content">
					{shop &&
						table &&
						(!settings.waiter_app.categories_menu_type ||
							settings.waiter_app.categories_menu_type == 1) &&
						courses.map((course, n) => (
							<div className="course" key={n}>
								<div className="course-name">{course.name || "Altro"}</div>
								{course.categories.map((c, i) => (
									<button
										key={i}
										onClick={() => {
											setCategory(c);
											setMenuVisible(false);
										}}
										className={"" + (c.id == category.id ? "active" : "")}
									>
										{c.name}
									</button>
								))}
							</div>
						))}
					<hr />
					<div className="course">
						<button
							onClick={() => {
								setShop(false);
								setMenuVisible(false);
							}}
						>
							<div className="icon">
								<ShopIcon />
							</div>
							Cambia Negozio
						</button>
						<button
							onClick={() => {
								setAlertData({
									title: "Sei sicuro?",
									message: "",
									showCancelButton: true,
									callback: async function () {
										setUser(false);
										setMenuVisible(false);
										localStorage.removeItem("waiter-user");
									},
								});
							}}
						>
							<div className="icon">
								<UserIcon />
							</div>
							LogOut
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Menu;
