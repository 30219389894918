import React, { useContext, useEffect, useState } from "react";
import { MainContext } from "../App";
import Api from "./api/api";
import Moment from "react-moment";
import "moment-timezone";
import "moment/locale/it";
import functions from "../utilities/functions";

function HistoryPopup() {
	const { settings, table, setPopupVisible, setLoading, setAlertData, tempTable } =
		useContext(MainContext);
	const [orders, setOrders] = useState([]);
	const [total, setTotal] = useState(0);
	const activeTable = table ? table : tempTable;

	useEffect(() => {
		loadHistory();
	}, []);

	useEffect(() => {
		loadHistory();
	}, [table, activeTable]);

	const loadHistory = async () => {
		console.log("loadHistory");
		setLoading(true);
		const response = await Api.postProtected("/waiters_app/history/", {
			table_id: activeTable.id,
		});
		setLoading(false);
		setOrders(response.rows);
		let t = 0;
		for (const order of response.rows) {
			t += parseFloat(order.total);
		}
		setTotal(t);
	};

	const setFlow = async (flow) => {
		console.log("setFlow");
		if (!activeTable.cart_id) return;
		setAlertData({
			title: "Sei sicuro?",
			message: "",
			showCancelButton: true,
			callback: async function () {
				setLoading(true);
				const response = await Api.postProtected("/tables/edit/" + activeTable.id + "/", {
					flow: flow,
				});
			},
		});
	};

	return (
		<div className="popup md">
			<div className="container">
				<header>
					<div className="buttons-group">
						<button
							className="btn btn-tertiary btn-square"
							onClick={() => setPopupVisible(false)}
						>
							&times;
						</button>
						<div>Ordini aperti {activeTable.name}</div>
					</div>
					<div className="buttons-group">
						{settings.waiter_app.enable_flow == 1 &&
							activeTable.cart_id != null &&
							activeTable.flow < 5 && (
								<button
									onClick={() => setFlow(parseInt(activeTable.flow) + 1)}
									className="btn btn-secondary btn-square2"
								>
									Avanza Portata
								</button>
							)}
					</div>
				</header>
				<div className="content group vertical">
					{!orders || orders.length == 0 ? (
						<div className="full-centered">Non ci sono ordini su questo tavolo</div>
					) : (
						orders.map((order, i) => (
							<div key={i} className="group vertical">
								<div className="group full title">
									<div>
										<div>ID:{order.id}</div>
										<div>N:{order.number}</div>
									</div>
									<div>
										<Moment format={"DD/MM/YYYY LT"} locale="it">
											{order.delivery_date}
										</Moment>
									</div>
								</div>
								{order.products.map((product, n) => (
									<div key={n} className="group full">
										<div>
											{product.qty}x {product.name}
											<div className="small">{product.user_nickname}</div>
										</div>
										<div>
											{functions.formatter.format(
												product.qty * product.price
											)}
										</div>
									</div>
								))}
							</div>
						))
					)}
				</div>
				<footer>
					<div>TOTALE</div>
					<div>{functions.formatter.format(total)}</div>
				</footer>
			</div>
		</div>
	);
}

export default HistoryPopup;
