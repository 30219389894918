import * as React from "react";

function ChevronUp(props) {
	return (
		<svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="none">
			<path
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
				d="M16 13l-6-6-6 6"
			></path>
		</svg>
	);
}

export default ChevronUp;
