import { useContext } from "react";
import { MainContext } from "../App";
import HamburgerIcon from "../icons/HamburgerIcon";

function Header() {
	const { shop, table, setTable, setMenuVisible } = useContext(MainContext);

	return (
		<header>
			<div>
				<button
					onClick={() => setMenuVisible(true)}
					className="btn btn-tertiary btn-square"
				>
					<HamburgerIcon />
				</button>
				<div>
					<div>{table ? table.name : "nessuno"}</div>
					<div className="extrasmall">{table.list_id ? table.list.name : "Base"}</div>
				</div>
			</div>
			<div className="header-right">
				{shop && (
					<>
						<button className="btn btn-tertiary" onClick={() => setTable(false)}>
							Tavoli
						</button>
					</>
				)}
			</div>
		</header>
	);
}

export default Header;
