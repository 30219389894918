import "../css/Loading.css";
import LoadIcon from "../icons/LoadIcon";

function Loading() {
	return (
		<div id="loading">
			<div className="content">
				<LoadIcon />
			</div>
		</div>
	);
}

export default Loading;
