import React, { useContext, useEffect, useState } from "react";
import { MainContext } from "../App";
import functions from "../utilities/functions";

function VariantsPopup() {
	const { handleAddProduct, variantsPopup, setVariantsPopup, saveProduct } =
		useContext(MainContext);
	const [selectedVariants, setSelectedVariants] = useState(() => {
		if (variantsPopup.index !== false) return variantsPopup.product.selectedVariants;
		else {
			let r = [];
			for (const variants_vategory of variantsPopup.product.variants_categories) {
				let v = {
					id: variants_vategory.id,
					name: variants_vategory.name,
					tot: 0,
					variants: [],
					products: [],
				};
				for (const variant of variants_vategory.variants) {
					v.variants.push({
						id: variant.id,
						name: variant.name,
						qty: 0,
						price: variant.price,
					});
				}
				for (const subproduct of variants_vategory.products) {
					v.products.push({
						id: subproduct.id,
						name: subproduct.name,
						qty: 0,
						price: subproduct.price,
					});
				}
				r.push(v);
			}
			return r;
		}
	});
	const [selectedIngredients, setSelectedIngredients] = useState(() => {
		if (variantsPopup.index !== false) return variantsPopup.product.selectedIngredients;
		else {
			let r = [];
			for (const ingredient of variantsPopup.product.ingredients) {
				r.push({
					id: ingredient.id,
					name: ingredient.name,
					selected: true,
				});
			}
			return r;
		}
	});
	const [valid, setValid] = useState(false);
	const [totals, setTotals] = useState(false);

	useEffect(() => {
		console.log("selectedIngredients se to", selectedIngredients);
	}, [selectedIngredients]);

	useEffect(() => {
		if (variantsPopup) checkVariants();
	}, [variantsPopup, selectedVariants]);

	const checkVariants = () => {
		console.log("checkVariants");
		console.log(selectedVariants);
		let v = true;
		let i = 0;
		let t = [];
		for (const variants_vategory of variantsPopup.product.variants_categories) {
			let qty = 0;
			for (const selectetVariant of selectedVariants[i].variants) qty += selectetVariant.qty;
			for (const selectetSubproduct of selectedVariants[i].products)
				qty += selectetSubproduct.qty;
			if (variants_vategory.min > 0) if (qty < variants_vategory.min) v = false;
			t.push(qty);
			i++;
		}
		console.log("v", v);
		console.log("t", t);
		setValid(v);
		setTotals(t);
	};

	const addVariant = (variantsCategoryIndex, variantIndex) => {
		console.log("addVariant");
		let arr = [...selectedVariants];
		arr[variantsCategoryIndex].tot++;
		arr[variantsCategoryIndex].variants[variantIndex].qty++;
		setSelectedVariants(arr);
	};

	const removeVariant = (variantsCategoryIndex, variantIndex) => {
		console.log("removeVariant");
		let arr = [...selectedVariants];
		if (arr[variantsCategoryIndex].variants[variantIndex].qty > 0) {
			arr[variantsCategoryIndex].tot--;
			arr[variantsCategoryIndex].variants[variantIndex].qty--;
		}
		setSelectedVariants(arr);
	};

	const addSubproduct = (variantsCategoryIndex, productIndex) => {
		console.log("addSubproduct");
		let arr = [...selectedVariants];
		arr[variantsCategoryIndex].tot++;
		arr[variantsCategoryIndex].products[productIndex].qty++;
		setSelectedVariants(arr);
	};

	const removeSubproduct = (variantsCategoryIndex, productIndex) => {
		console.log("removeSubproduct");
		let arr = [...selectedVariants];
		if (arr[variantsCategoryIndex].products[productIndex].qty > 0) {
			arr[variantsCategoryIndex].tot--;
			arr[variantsCategoryIndex].products[productIndex].qty--;
		}
		setSelectedVariants(arr);
	};

	const handleIngredients = (index, value) => {
		console.log("handleIngredients");
		let arr = [...selectedIngredients];
		arr[index].selected = value;
		setSelectedIngredients(arr);
	};

	return (
		<div className="popup lg">
			<div className="container">
				<header>
					<div className="buttons-group">
						<button
							className="btn btn-tertiary btn-square"
							onClick={() => setVariantsPopup(false)}
						>
							&times;
						</button>
						<div>{variantsPopup.product.name}</div>
					</div>
				</header>
				<div className="content list-variants">
					{variantsPopup.product.variants_categories.map((variants_vategory, i) => (
						<div key={i}>
							<div
								className={
									totals[i] < variants_vategory.min ? "alert-box" : "color-box"
								}
							>
								<div className="title">{variants_vategory.name}</div>
								<div className="small">
									Min: {variants_vategory.min} - Max: {variants_vategory.max}
								</div>
							</div>
							{variants_vategory.variants.map((variant, n) => (
								<div key={n} className="list-button">
									<div>{variant.name}</div>
									<div>
										<div className="buttons-group">
											{functions.formatter.format(variant.price)}
											{selectedVariants[i].variants[n].qty > 0 ? (
												<button
													onClick={() => removeVariant(i, n)}
													className="btn btn-primary btn-square"
												>
													&#8722;
												</button>
											) : (
												<button className="btn btn-square">&#8722;</button>
											)}
											<div>{selectedVariants[i].variants[n].qty}</div>
											{totals[i] < variants_vategory.max ? (
												<button
													onClick={() => addVariant(i, n)}
													className="btn btn-primary btn-square"
												>
													&#43;
												</button>
											) : (
												<button className="btn btn-square">&#43;</button>
											)}
										</div>
									</div>
								</div>
							))}
							{variants_vategory.products.map((subproduct, n) => (
								<div key={n} className="list-button">
									<div>{subproduct.name}</div>
									<div>
										<div className="buttons-group">
											{functions.formatter.format(subproduct.price)}
											{selectedVariants[i].products[n].qty > 0 ? (
												<button
													onClick={() => removeSubproduct(i, n)}
													className="btn btn-tertiary btn-square"
												>
													&#8722;
												</button>
											) : (
												<button className="btn btn-square">&#8722;</button>
											)}
											<div>{selectedVariants[i].products[n].qty}</div>
											{totals[i] < variants_vategory.max ? (
												<button
													onClick={() => addSubproduct(i, n)}
													className="btn btn-tertiary btn-square"
												>
													&#43;
												</button>
											) : (
												<button className="btn btn-square">&#43;</button>
											)}
										</div>
									</div>
								</div>
							))}
						</div>
					))}
					{variantsPopup.product.ingredients.length > 0 && (
						<div>
							<div className="color-box">
								<div className="title">Ingredienti</div>
							</div>

							<div className="ingredients-list">
								{variantsPopup.product.ingredients.map((ingredient, n) => (
									<div key={n}>
										<input
											id={"ingredient-" + n}
											type="checkbox"
											defaultChecked
											onChange={(e) => handleIngredients(n, e.target.checked)}
											disabled={ingredient.removable != 1}
										/>
										<label htmlFor={"ingredient-" + n}>{ingredient.name}</label>
									</div>
								))}
							</div>
						</div>
					)}
				</div>
				<div className="variants-addButton">
					{valid ? (
						<>
							{variantsPopup.index === false ? (
								<button
									onClick={() =>
										handleAddProduct(
											variantsPopup.product,
											selectedVariants,
											selectedIngredients
										)
									}
									className="btn btn-primary btn-block"
								>
									Aggiungi
								</button>
							) : (
								<button
									onClick={() =>
										saveProduct(
											variantsPopup.product,
											selectedVariants,
											selectedIngredients
										)
									}
									className="btn btn-primary btn-block"
								>
									Salva modifiche
								</button>
							)}
						</>
					) : (
						<button className="btn btn-block">Effettua tutte le scelte</button>
					)}
				</div>
			</div>
		</div>
	);
}

export default VariantsPopup;
