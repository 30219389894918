import { useContext, useEffect, useState } from "react";
import { MainContext } from "../App";
import Product from "./cart/Product";
import functions from "../utilities/functions";
import NoteIcon from "../icons/NoteIcon";

function Cart() {
	const {
		table,
		list,
		emptyCart,
		checkout,
		setAlertData,
		setPopupVisible,
		cart,
		flow,
		setFlow,
		settings,
		setCartVisible,
	} = useContext(MainContext);
	const [count, setCount] = useState(0);
	const [products, setProducts] = useState([]);

	useEffect(() => {
		sortProduts(cart);
		countProduts(cart);
	}, [cart]);

	const sortProduts = (c) => {
		if (!c) return;
		let r = [];
		for (let index = 1; index <= 5; index++) {
			let flow = {
				id: index,
				name: "Portata " + index,
				products: [],
			};
			let i = 0;
			for (const p of c.products) {
				p.index = i;
				if (parseInt(p.flow) == index) flow.products.push(p);
				i++;
			}
			r.push(flow);
		}
		setProducts(r);
	};

	const countProduts = (c) => {
		if (!c) return;
		let t = 0;
		for (const e of c.products) {
			t += parseInt(e.qty);
		}
		setCount(t);
	};

	const handleCheckout = () => {
		if (count == 0) return;
		setAlertData({
			title: "Confermi il tuo ordine?",
			message: "Clicca si per procedere",
			showCancelButton: true,
			callback: checkout,
		});
	};

	const handleEmpty = () => {
		if (count == 0) return;
		setAlertData({
			title: "Sei sicuro?",
			message: "",
			showCancelButton: true,
			callback: emptyCart,
		});
	};

	const getCartTotal = () => {
		let t = 0;
		for (const product of cart.products) {
			t += parseInt(product.qty) * parseFloat(product.price);
		}
		return t;
	};

	return (
		<div className="popup md">
			<div className="container">
				<header>
					<div className="buttons-group">
						<button
							className="btn btn-tertiary btn-square"
							onClick={() => setCartVisible(false)}
						>
							&times;
						</button>
						<div>
							<div>Tavolo: {table ? table.name : "nessuno"}</div>
							<div className="extrasmall">
								Listino: {table.list_id ? table.list.name : "Base"}
							</div>
						</div>
					</div>
					<div className="buttons-group">
						<button
							className="btn btn-tertiary btn-square"
							onClick={() => setPopupVisible("orderNotes")}
						>
							<NoteIcon />
						</button>
					</div>
				</header>
				<div className="content">
					{products.map((f, n) => (
						<div key={n}>
							{settings.waiter_app.enable_flow == 1 && (
								<button
									className={f.id == flow ? "list-title selected" : "list-title"}
									onClick={() => setFlow(f.id)}
								>
									{f.name}
								</button>
							)}
							{f.products.map((product, i) => (
								<Product product={product} key={i} />
							))}
						</div>
					))}
					{cart.notes && (
						<div className="text-box">
							Note:<div className="small">{cart.notes}</div>
						</div>
					)}
				</div>
				<footer className="buttons-group">
					<div>{functions.formatter.format(getCartTotal())}</div>
					<button onClick={() => handleEmpty()} className="btn btn-tertiary btn-block">
						Svuota
					</button>
					<button
						onClick={() => handleCheckout()}
						className="btn btn-secondary btn-block"
					>
						Invia
					</button>
				</footer>
			</div>
		</div>
	);
}

export default Cart;
